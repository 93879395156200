export const USER_STATUS = {
    PENDING: "PENDING",
    INACTIVE: "INACTIVE",
    REFUSE: "REFUSE",
    POSTED: "POSTED",
}


export const USERS_STATUS = [
    'active', 'inactive'
]