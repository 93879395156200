import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import ToastMessage from "components/toast/ToastMessage";
import { authSignIn } from "constants/routeConstant";
import { Navigate, Route, Routes } from "react-router-dom";
import routes from "routes.js";

export default function Auth() {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";
  return (
    <div>
      <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
        <FixedPlugin />
        <main className={`mx-auto min-h-screen`}>
          <div className="relative flex">
            <div className="mx-auto flex min-h-full w-full flex-col justify-start lg:h-screen  lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
              <div className="mb-auto flex flex-col ">
                {/* <div className="mx-auto flex h-fit w-fit items-center hover:cursor-pointer">
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.70994 2.11997L2.82994 5.99997L6.70994 9.87997C7.09994 10.27 7.09994 10.9 6.70994 11.29C6.31994 11.68 5.68994 11.68 5.29994 11.29L0.709941 6.69997C0.319941 6.30997 0.319941 5.67997 0.709941 5.28997L5.29994 0.699971C5.68994 0.309971 6.31994 0.309971 6.70994 0.699971C7.08994 1.08997 7.09994 1.72997 6.70994 2.11997V2.11997Z"
                      fill="#A3AED0"
                    />
                  </svg>
                </div> */}
                {/* toast message */}
                <ToastMessage />
                <Routes>
                  {getRoutes(routes)}
                  <Route
                    path="/"
                    element={<Navigate to={authSignIn} replace />}
                  />
                </Routes>
                <div
                  className="absolute right-0 min-h-screen flex-col items-center justify-center 
                 bg-gradient-to-r from-[#422AFB] to-blue-500  lg:w-[49vw] 2xl:w-[44vw]
                 bg-cover bg-center lg:rounded-bl-[120px] xl:rounded-bl-[200px] lg:flex md:hidden hidden"
                >
                  <span className="text-white font-bold text-8xl">{"9JOB"}</span>
                  <span className="text-white font-bold text-5xl">{"Admin"}</span>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
